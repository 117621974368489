import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'
import {
  loadGameStateFromLocalStorage,
} from '../lib/localStorage'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toUpperCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string,solution: string) => {
  return solution === word
}

export const getWordOfDay = () => {
  let store = loadGameStateFromLocalStorage();
  if(store?.solution !== undefined){
    return {
      solution: store.solution,
      solutionIndex: WORDS.findIndex(value => value === store?.solution)
    }
  }
  const index = Math.floor(Math.random()*WORDS.length);

  return {
    solution: WORDS[index].toUpperCase(),
    solutionIndex: index,
  }
}

export const { solution, solutionIndex } = getWordOfDay()
