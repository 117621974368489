export const WORDS = [
  "ROPA",
  "CCPA",
"CPRA",
"GDPR",
"DPIA",
"CNIL",
"CDPA",
"APPI",
"CIPP",
"CIPT",
"CPPA",
"DSAR",
"GLBA",
"IAPP",
"LGPD",
"NIST",
"PIPA",
"PIPC",
"PIPL",
"POPI",
"RTBF",

]

export const SIZE = 4;